import React from 'react';
import { Icon } from './index';

const ColumnIcon = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 31.92 30.92"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M7.3,8.92H1.62A1.63,1.63,0,0,1,0,7.3V1.62A1.63,1.63,0,0,1,1.62,0H7.3A1.63,1.63,0,0,1,8.92,1.62V7.3A1.63,1.63,0,0,1,7.3,8.92ZM2.5,6.42H6.42V2.5H2.5ZM7.3,2.5h0Zm23,6.42H12.62A1.63,1.63,0,0,1,11,7.3V1.62A1.63,1.63,0,0,1,12.62,0H30.3a1.63,1.63,0,0,1,1.62,1.62V7.3A1.63,1.63,0,0,1,30.3,8.92ZM13.5,6.42H29.42V2.5H13.5ZM30.3,2.5h0ZM7.3,19.92H1.62A1.63,1.63,0,0,1,0,18.3V12.62A1.63,1.63,0,0,1,1.62,11H7.3a1.63,1.63,0,0,1,1.62,1.62V18.3A1.63,1.63,0,0,1,7.3,19.92Zm-4.8-2.5H6.42V13.5H2.5ZM7.3,13.5h0Zm23,6.42H12.62A1.63,1.63,0,0,1,11,18.3V12.62A1.63,1.63,0,0,1,12.62,11H30.3a1.63,1.63,0,0,1,1.62,1.62V18.3A1.63,1.63,0,0,1,30.3,19.92Zm-16.8-2.5H29.42V13.5H13.5ZM30.3,13.5h0ZM7.3,30.92H1.62A1.63,1.63,0,0,1,0,29.3V23.62A1.63,1.63,0,0,1,1.62,22H7.3a1.63,1.63,0,0,1,1.62,1.62V29.3A1.63,1.63,0,0,1,7.3,30.92Zm-4.8-2.5H6.42V24.5H2.5ZM7.3,24.5h0Zm23,6.42H12.62A1.63,1.63,0,0,1,11,29.3V23.62A1.63,1.63,0,0,1,12.62,22H30.3a1.63,1.63,0,0,1,1.62,1.62V29.3A1.63,1.63,0,0,1,30.3,30.92Zm-16.8-2.5H29.42V24.5H13.5ZM30.3,24.5h0Z"
    />
  </svg>,
);

export default ColumnIcon;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import classNames from 'classnames';

import Link from '../../../components/Link';
import CourseItem from '../../../components/CourseItem/CourseItem';
import SkholeIcon11 from '../../../components/statelessComponents/Icons/SkholeIcon11';
import SkholeIcon09 from '../../../components/statelessComponents/Icons/SkholeIcon09';

import messages from '../coursesMessges';

import s from './ContinueLearning.scss';

@injectIntl
@withStyles(s)
export default class ContinueLearning extends React.Component {
  static propTypes = {
    course: PropTypes.shape({}),
    tasks: PropTypes.number.isRequired,
    exam: PropTypes.shape({}),
    quiz: PropTypes.shape({
      quizId: PropTypes.shape({
        _id: PropTypes.string,
      }),
    }),
    user: PropTypes.shape({}).isRequired,
    unreadNotifications: PropTypes.number.isRequired,
    afterCourseRemovedFromFavorite: PropTypes.func,
    handleOpenNotificationPopover: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    inRow: PropTypes.bool,
  };

  static defaultProps = {
    course: undefined,
    afterCourseRemovedFromFavorite: () => {},
    exam: undefined,
    quiz: undefined,
    inRow: false,
  };

  render() {
    const {
      tasks,
      course,
      exam,
      quiz,
      unreadNotifications,
      user,
      afterCourseRemovedFromFavorite = () => {},
      handleOpenNotificationPopover = () => {},
      inRow,
    } = this.props;
    return (
      <div className={s.continueLearning}>
        <h2>
          {course &&
            (_.get(user, 'loginNumber', 0) > 1 ||
              !!_.get(course, 'progress', 0)) && (
              <FormattedMessage {...messages.continueLearningTitle} />
            )}
          {_.get(user, 'loginNumber', 0) <= 1 &&
            course &&
            _.get(course, 'progress', 0) === 0 && (
              <FormattedMessage {...messages.startLearningTitle} />
            )}
        </h2>
        <div
          className={classNames(
            s.continueLearningContent,
            inRow && s.landscapeIphonePixel,
          )}
        >
          {!_.isEmpty(course) && (
            <div
              className={classNames(
                s.dashboardCourseItemContainer,
                inRow && s.landscapeIphonePixelContainer,
              )}
            >
              <CourseItem
                item={course}
                disableHover
                isInDashboard
                afterCourseRemovedFromFavorite={afterCourseRemovedFromFavorite}
              />
            </div>
          )}
          <div className={s.dashboardStats}>
            {tasks > 0 && (
              <Link to="/tasks">
                <div className={s.notificationBadge}>
                  <span>{tasks}</span>
                </div>
                <FormattedMessage
                  {...messages.taskToDoText}
                  values={{
                    amount: tasks || 0,
                  }}
                />
              </Link>
            )}
            {!_.isEmpty(exam) && (
              <Link
                to="/tasks"
                className={classNames(s.examText, s.hideOnMobile)}
              >
                <div>
                  <SkholeIcon11 className={s.examScoreIcon} />
                </div>
                {/* <div className={s.examScoreIcon}>{SkholeIcon11}</div> */}
                <FormattedMessage
                  {...messages.examScoreText}
                  values={{
                    score: `${_.get(exam, 'totalPoints', 0)}/${_.get(
                      exam,
                      'quizPoints',
                      0,
                    )}`,
                    examName: `${_.get(exam, 'taskId.name')}`,
                  }}
                />
              </Link>
            )}
            {quiz && _.get(quiz, 'courseId.slug') && (
              <Link
                to={`/courses/${_.get(quiz, 'courseId.slug')}/quizzes/${
                  quiz.quizId._id
                }`}
                className={classNames(s.examText, s.hideOnMobile)}
              >
                <div>
                  <SkholeIcon11 className={s.examScoreIcon} />
                </div>

                <FormattedMessage
                  {...messages.quizScoreText}
                  values={{
                    score: `${_.get(quiz, 'totalPoints', 0)}/${_.get(
                      quiz,
                      'quizPoints',
                      0,
                    )}`,
                    quizName: `${_.get(quiz, 'quizId.name')}`,
                  }}
                />
              </Link>
            )}
            {!!unreadNotifications && (
              <span
                className={s.messagesText}
                role="link"
                tabIndex={0}
                onClick={handleOpenNotificationPopover}
                onKeyPress={handleOpenNotificationPopover}
              >
                <SkholeIcon09 className={s.msgIcon} />
                <FormattedMessage
                  {...messages.haveMessagesText}
                  values={{
                    amount: unreadNotifications,
                  }}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

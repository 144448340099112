/* eslint-disable import/prefer-default-export */

import {
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  RESET_COURSES,
} from '../constants';
import { buildQuery } from '../helpers';

export function getCourses(
  params = { page: 1, perPage: 32, sort: '-createDate', search: '' },
  doNotAppend = false,
) {
  const query = buildQuery(params);

  return {
    types: [GET_COURSES_REQUEST, GET_COURSES_SUCCESS, GET_COURSES_FAIL],
    promise: fetch =>
      fetch(`/api/courses/with-related-lessons?${query}`, {
        method: 'GET',
      }),
    doNotAppend,
  };
}

export function reset() {
  return {
    type: RESET_COURSES,
  };
}

/* eslint-disable import/prefer-default-export */

import {
  GET_DASHBOARD_DISCUSSIONS_REQUEST,
  GET_DASHBOARD_DISCUSSIONS_SUCCESS,
  GET_DASHBOARD_DISCUSSIONS_FAIL,
  RESET_DASHBOARD_DISCUSSIONS,
} from '../constants';
import { buildQuery } from '../helpers';

export function getFollowedDiscussions(
  params = { page: 1, perPage: 20, sort: '-createDate', search: '' },
) {
  const query = buildQuery(params);

  return {
    types: [
      GET_DASHBOARD_DISCUSSIONS_REQUEST,
      GET_DASHBOARD_DISCUSSIONS_SUCCESS,
      GET_DASHBOARD_DISCUSSIONS_FAIL,
    ],
    promise: fetch =>
      fetch(`/api/discussions?${query}`, {
        method: 'GET',
      }),
  };
}

export function resetFollowedDiscussion() {
  return {
    type: RESET_DASHBOARD_DISCUSSIONS,
  };
}

import React from 'react';
import { Icon } from './index';

const SkholeIcon09 = Icon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 40 40"
    version="1.1"
    viewBox="0 0 40 40"
    xmlSpace="preserve"
  >
    <path
      d="M33.9 8.3H6.1c-1.8 0-3.3 1.5-3.3 3.3v17.6c0 1.8 1.5 3.3 3.3 3.3h27.8c1.8 0 3.3-1.5 3.3-3.3V11.6c0-1.8-1.5-3.3-3.3-3.3zm-1.8 2.5L19.7 21.7 7.1 10.8h25zm1.8 19.3H6.1c-.5 0-.8-.4-.8-.8V12.5l13.6 11.8c.2.2.5.3.8.3.3 0 .6-.1.8-.3l14.3-12.5v17.5c0 .4-.4.8-.9.8z"
      className="st1"
    />
  </svg>,
);

export default SkholeIcon09;

import React from 'react';
import { Icon } from './index';

const SkholeIcon41 = Icon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 40 40"
    version="1.1"
    viewBox="0 0 40 40"
    xmlSpace="preserve"
  >
    <path
      d="M3.6 19.3c.1-.1.1-.2.2-.3l10.6-9.8c.5-.5 1.3-.4 1.8.1s.4 1.3-.1 1.8l-8.3 7.7h27.5c.7 0 1.2.6 1.2 1.2s-.6 1.2-1.2 1.2H7.8l8.3 7.7c.5.5.5 1.3.1 1.8-.2.3-.6.4-.9.4-.3 0-.6-.1-.8-.3L3.8 20.9c-.3-.2-.4-.6-.4-.9 0-.2.1-.5.2-.7z"
      className="st1"
    />
  </svg>,
);

export default SkholeIcon41;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Arrow = ({
  className = '',
  style = {},
  onClick = () => {},
  customClassName = '',
  Component,
}) => (
  <Component
    className={classNames(className, customClassName)}
    style={style}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
  />
);

Arrow.propTypes = {
  customClassName: PropTypes.string,
  className: PropTypes.string,
  Component: PropTypes.element.isRequired,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

Arrow.defaultProps = {
  className: '',
  style: {},
  onClick: () => {},
  customClassName: '',
};

export default Arrow;

import React from 'react';
import { Icon } from './index';

const FilterIcon = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M5.42,10.85a5.42,5.42,0,0,0,5.27-4.18H32.25a1.25,1.25,0,0,0,0-2.5H10.69a5.42,5.42,0,1,0-5.27,6.68Zm0-8.35A2.93,2.93,0,1,1,2.5,5.42,2.93,2.93,0,0,1,5.42,2.5ZM32.25,15.8H29.77a5.41,5.41,0,0,0-10.54,0h-18a1.25,1.25,0,0,0,0,2.5h18a5.42,5.42,0,0,0,10.54,0h2.48a1.25,1.25,0,0,0,0-2.5ZM24.5,20a2.93,2.93,0,1,1,2.92-2.92A2.92,2.92,0,0,1,24.5,20Zm7.75,7.45H15.46a5.42,5.42,0,0,0-10.54,0H1.25a1.25,1.25,0,0,0,0,2.5H4.92a5.41,5.41,0,0,0,10.54,0H32.25a1.25,1.25,0,0,0,0-2.5ZM10.19,31.6a2.93,2.93,0,1,1,2.93-2.93A2.93,2.93,0,0,1,10.19,31.6Z"
    />
  </svg>,
);

export default FilterIcon;

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';

import InputLabel from '@material-ui/core/InputLabel';
import messages from '../coursesMessges';
import {
  getContentCategoriesForFilter,
  isAdmin,
  isWhitelabeledOrganization,
} from '../../../helpers';
import isLoggedIn from '../../../helpers/checkAuth';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Filter.scss';
import { ToolbarSelectField } from '../../../components/statelessComponents/TableToolbar/Forms';
import SkholeIcon42 from '../../../components/statelessComponents/Icons/SkholeIcon42';
import FilterIcon from '../../../components/statelessComponents/Icons/FiltersIcon';
import ColumnIcon from '../../../components/statelessComponents/Icons/ColumnIcon';
import GridIcon from '../../../components/statelessComponents/Icons/GridIcon';
import CategoryCollapse from './CategoryCollapse';

const IconComponent = ({ className, ...rest }) => (
  <SkholeIcon42 {...rest} className={classNames(className, s.selectIcon)} />
);

IconComponent.propTypes = {
  className: PropTypes.string.isRequired,
};

@injectIntl
@withStyles(s)
@connect(state => ({
  lang: state.intl.locale,
  loadingLessons: state.homeLessons.loading,
}))
export default class Filter extends React.Component {
  static propTypes = {
    filter: PropTypes.string.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onChangeSort: PropTypes.func.isRequired,
    onChangeSortOrder: PropTypes.func.isRequired,
    sortValue: PropTypes.string.isRequired,
    sortOrderValue: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    clearFilter: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    hasPristineState: PropTypes.bool.isRequired,
    auth: PropTypes.shape({}).isRequired,
    lang: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired, // eslint-disable-line
    pendingCategories: PropTypes.array.isRequired, // eslint-disable-line
    handleExpandIndex: PropTypes.func.isRequired,
    handleCollapseIndex: PropTypes.func.isRequired,
    isIndexExpanded: PropTypes.bool.isRequired,
    loadingCourses: PropTypes.bool.isRequired,
    loadingLessons: PropTypes.bool.isRequired,
    toggleSelectAllContentCategories: PropTypes.func.isRequired,
    handleToggleContentCategory: PropTypes.func.isRequired,
    loadingContentCategories: PropTypes.bool.isRequired,
    isLoadedContentCategories: PropTypes.bool.isRequired,
    isFiltersMobileExpanded: PropTypes.bool.isRequired,
    handleFiltersMobileExpanded: PropTypes.func.isRequired,
    setIsColumnDisplayMode: PropTypes.func.isRequired,
    displayItemsInColumn: PropTypes.bool.isRequired,
    contentLangId: PropTypes.string.isRequired,
    onContentLanguageChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    onContentTypeChange: PropTypes.func.isRequired,

    tags: PropTypes.array.isRequired, // eslint-disable-line
    pendingTags: PropTypes.array.isRequired, // eslint-disable-line
    toggleSelectAllTags: PropTypes.func.isRequired,
    handleToggleTag: PropTypes.func.isRequired,
    loadingTags: PropTypes.bool.isRequired,
    isLoadedTags: PropTypes.bool.isRequired,
    wereTagsEmpty: PropTypes.bool.isRequired,
    isTagsIndexExpanded: PropTypes.bool.isRequired,
    handleCollapseTagsIndex: PropTypes.func.isRequired,
    handleExpandTagsIndex: PropTypes.func.isRequired,

    amountApplied: PropTypes.number.isRequired,
  };

  static contextTypes = {
    whitelabeling: PropTypes.shape({}),
    contentLangs: PropTypes.arrayOf(
      PropTypes.shape({
        createDate: PropTypes.string,
        lang: PropTypes.string,
        name: PropTypes.string,
        _id: PropTypes.string,
      }),
    ).isRequired,
  };

  anchorEl = null;

  anchorTagsEl = null;

  handleDisplayListClick = () => {
    this.props.setIsColumnDisplayMode(true);
  };

  handleDisplayPlateClick = () => {
    this.props.setIsColumnDisplayMode(false);
  };

  render() {
    const {
      filter,
      onFilterChange = () => {},
      contentLangId,
      onContentLanguageChange = () => {},
      onChangeSort = () => {},
      onChangeSortOrder = () => {},
      sortValue,
      sortOrderValue,
      search,
      lang,
      clearFilter,
      hasPristineState,
      auth,
      pendingCategories,
      categories,
      isIndexExpanded,
      loadingCourses,
      toggleSelectAllContentCategories,
      handleCollapseIndex,
      handleToggleContentCategory,
      handleExpandIndex,
      loadingContentCategories,
      isLoadedContentCategories,
      isFiltersMobileExpanded,
      handleFiltersMobileExpanded,
      displayItemsInColumn,
      intl: { formatMessage, locale },
      loadingLessons,
      type,
      onContentTypeChange,

      isTagsIndexExpanded,
      pendingTags,
      tags,
      toggleSelectAllTags,
      handleToggleTag,
      loadingTags,
      isLoadedTags,
      handleCollapseTagsIndex,
      handleExpandTagsIndex,
      wereTagsEmpty,

      amountApplied,
    } = this.props;

    const { whitelabeling, contentLangs } = this.context;

    const isSearchEmpty = search.trim().length < 3;

    const serviceNamePossessive = _.get(
      whitelabeling,
      `serviceNamePossessive.${lang}`,
      formatMessage(messages.serviceNamePossessive),
    );

    const serviceName = _.get(whitelabeling, `serviceName.${lang}`, 'Skhole');

    const serviceNameToUse =
      lang === 'fi-FI' ? serviceNamePossessive : serviceName;

    const courseTypes = [
      {
        value: '',
        tooltipValue: formatMessage(messages.allCoursesBtnTooltip),
        label: <FormattedMessage {...messages.allCoursesBtnLabel} />,
        disabled: true,
      },
      ...(!isWhitelabeledOrganization(_.get(auth, 'user.mainGroup')) ||
      _.get(whitelabeling, 'allowServiceNameFilter')
        ? [
            {
              value: 'skhole',
              tooltipValue: (
                <FormattedMessage
                  {...messages.skholeCoursesBtnToolTip}
                  values={{ serviceNameToUse }}
                />
              ),
              label: (
                <FormattedMessage
                  {...messages.skholeCoursesBtnLabel}
                  values={{ serviceNamePossessive }}
                />
              ),
            },
          ]
        : []),
      ...(isAdmin(auth)
        ? [
            {
              value: 'customers_courses',
              tooltipValue: (
                <FormattedMessage
                  {...messages.customersCoursesToolTip}
                  values={{ serviceNameToUse }}
                />
              ),
              label: <FormattedMessage {...messages.customersCourses} />,
            },
          ]
        : []),
      ...(isWhitelabeledOrganization(_.get(auth, 'user.mainGroup'))
        ? [
            {
              value: 'my_courses',
              tooltipValue: formatMessage(messages.favouriteCoursesBtnTooltip),
              label: <FormattedMessage {...messages.favouriteCoursesBtn} />,
            },
          ]
        : [
            {
              value: 'my_courses',
              tooltipValue: formatMessage(messages.myCoursesBtnTooltip),
              label: <FormattedMessage {...messages.myCoursesBtn} />,
            },
          ]),
    ];

    const contentTypeFilterOptions = [
      {
        value: 'all',
        tooltipValue: formatMessage(messages.allLabel),
        label: <FormattedMessage {...messages.allLabel} />,
      },
      {
        value: 'normal',
        tooltipValue: formatMessage(messages.normalType),
        label: <FormattedMessage {...messages.normalType} />,
      },
      {
        value: 'livestream',
        tooltipValue: formatMessage(messages.livestreamType),
        label: <FormattedMessage {...messages.livestreamType} />,
      },
      {
        value: 'podcast',
        tooltipValue: formatMessage(messages.podcastType),
        label: <FormattedMessage {...messages.podcastType} />,
      },
    ];

    return (
      <section className={s.container}>
        <Button
          className={classNames(
            s.filterBtn,
            s.filterBtnRoot,
            s.browseCategoryBtn,
            s.mobileFilters,
            s.mobileFiltersCollapseBtn,
          )}
          onClick={handleFiltersMobileExpanded}
        >
          <FormattedMessage {...messages.filtersAndOrderBtnLabel} />
          <div>
            {!hasPristineState && !!amountApplied && (
              <span className={s.mobileFilterAmountApplied}>
                <FormattedMessage
                  {...messages.amountAppliedLabel}
                  values={{ amount: amountApplied }}
                />
              </span>
            )}
            {!isFiltersMobileExpanded ? (
              <FilterIcon className={s.filterIcon} />
            ) : (
              <CloseIcon className={s.filterIcon} />
            )}
          </div>
        </Button>
        <section
          className={classNames(s.filter, {
            [s.filtersMobileExpanded]: isFiltersMobileExpanded,

            [s.authorizedFilterTagsHidden]:
              !tags.length &&
              wereTagsEmpty &&
              isLoggedIn(auth) &&
              getContentCategoriesForFilter(auth),

            [s.authorizedFilterContentCategoriesHidden]:
              !!tags.length &&
              isLoggedIn(auth) &&
              !getContentCategoriesForFilter(auth),

            [s.authorizedFilterContentCategoriesAndTagsHidden]:
              !tags.length &&
              wereTagsEmpty &&
              isLoggedIn(auth) &&
              !getContentCategoriesForFilter(auth),

            [s.unAuthorizedFilterContentCategoriesHidden]:
              !!tags.length && !isLoggedIn(auth),
            [s.unAuthorizedFilterContentCategoriesAndTagsHidden]:
              !tags.length && !isLoggedIn(auth),

            [s.authorizedLangFieldHidden]:
              !!search && getContentCategoriesForFilter(auth),
            [s.authorizedLangFieldAndContentCategoriesHidden]: !!search,
            [s.unAuthorizedLangFieldHidden]: !!search && !isLoggedIn(auth),
          })}
        >
          {!search &&
            isLoggedIn(auth) &&
            !!getContentCategoriesForFilter(auth) && (
              <>
                <Tooltip
                  title={formatMessage(messages.browseByCategoryBtnTooltip)}
                >
                  <div className={s.categoriesBlock}>
                    <InputLabel className={s.categoriesFilterLabel}>
                      <FormattedMessage {...messages.categoriesBlockLabel} />
                    </InputLabel>

                    <Button
                      className={classNames(
                        s.filterBtn,
                        s.filterBtnRoot,
                        s.browseCategoryBtn,
                        s.categoryArea,
                      )}
                      classes={{
                        focusVisible: s.focused,
                      }}
                      disabled={
                        loadingCourses ||
                        loadingContentCategories ||
                        loadingLessons ||
                        !isLoadedContentCategories
                      }
                      buttonRef={node => {
                        this.anchorEl = node;
                      }}
                      onClick={
                        isIndexExpanded
                          ? handleCollapseIndex
                          : handleExpandIndex
                      }
                      disableFocusRipple
                    >
                      {pendingCategories.length &&
                      pendingCategories.length !== categories.length
                        ? formatMessage(
                            messages.browseByCategorySelectedBtnLabel,
                            {
                              amountSelected: pendingCategories.length,
                            },
                          )
                        : formatMessage(
                            messages.browseByContentCategoryBtnLabel,
                          )}
                      <SkholeIcon42
                        className={classNames(s.selectIcon, {
                          [s.flipHorizontally]: isIndexExpanded,
                        })}
                      />
                    </Button>
                  </div>
                </Tooltip>
                <CategoryCollapse
                  loadingCourses={loadingCourses}
                  pendingCategories={pendingCategories}
                  toggleSelectAllContentCategories={
                    toggleSelectAllContentCategories
                  }
                  categories={categories}
                  handleToggleContentCategory={handleToggleContentCategory}
                  handleCollapseIndex={handleCollapseIndex}
                  isIndexExpanded={isIndexExpanded}
                  isLoadedContentCategories={isLoadedContentCategories}
                  anchorEl={() => this.anchorEl}
                  nameMapper={item => item.name[locale]}
                />
              </>
            )}

          {!search && (!!tags.length || !wereTagsEmpty) && (
            <>
              <Tooltip title={formatMessage(messages.browseByTagBtnTooltip)}>
                <div className={s.categoriesBlock}>
                  <InputLabel className={s.categoriesFilterLabel}>
                    <FormattedMessage {...messages.tagsBlockLabel} />
                  </InputLabel>

                  <Button
                    className={classNames(
                      s.filterBtn,
                      s.filterBtnRoot,
                      s.browseCategoryBtn,
                      s.tagsArea,
                    )}
                    classes={{
                      focusVisible: s.focused,
                    }}
                    disabled={
                      loadingCourses ||
                      loadingTags ||
                      loadingLessons ||
                      !isLoadedTags
                    }
                    buttonRef={node => {
                      this.anchorTagsEl = node;
                    }}
                    onClick={
                      isTagsIndexExpanded
                        ? handleCollapseTagsIndex
                        : handleExpandTagsIndex
                    }
                    disableFocusRipple
                  >
                    {pendingTags.length && pendingTags.length !== tags.length
                      ? formatMessage(
                          messages.browseByCategorySelectedBtnLabel,
                          {
                            amountSelected: pendingTags.length,
                          },
                        )
                      : formatMessage(messages.browseByCategoryBtnLabel)}
                    <SkholeIcon42
                      className={classNames(s.selectIcon, {
                        [s.flipHorizontally]: isTagsIndexExpanded,
                      })}
                    />
                  </Button>
                </div>
              </Tooltip>

              <CategoryCollapse
                loadingCourses={loadingCourses}
                pendingCategories={pendingTags}
                toggleSelectAllContentCategories={toggleSelectAllTags}
                categories={tags}
                handleToggleContentCategory={handleToggleTag}
                handleCollapseIndex={handleCollapseTagsIndex}
                isIndexExpanded={isTagsIndexExpanded}
                isLoadedContentCategories={isLoadedTags}
                anchorEl={() => this.anchorTagsEl}
                nameMapper={item => item.name}
              />
            </>
          )}
          <div
            className={classNames(
              s.flex,
              s.courseTypeFilters,
              s.classificationArea,
              {
                [s.hide]: !isLoggedIn(auth),
              },
            )}
          >
            {isLoggedIn(auth) && (
              <ToolbarSelectField
                label={formatMessage(messages.classificationFilterLabel)}
                InputLabelProps={{
                  classes: {
                    formControl: s.selectLabel,
                  },
                }}
                value={filter || 'all'}
                onChange={onFilterChange}
                classes={{
                  textField: s.filterSelect,
                  selectRoot: s.filterSelect,
                  formRoot: s.formRootAllCourses,
                  selectIcon: s.toolbarSelectIcon,
                  select: s.allCoursesSelect,
                }}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                }}
                disabled={loadingCourses}
                role="button"
              >
                {courseTypes.map(option => (
                  <MenuItem
                    className={s.font}
                    key={option.value + 1}
                    value={option.value || 'all'}
                  >
                    <Tooltip title={option.tooltipValue}>
                      <div className={s.courseTypeMenuItems}>
                        {option.label}
                      </div>
                    </Tooltip>
                  </MenuItem>
                ))}
              </ToolbarSelectField>
            )}
          </div>

          <div
            className={classNames(s.flex, s.courseTypeFilters, s.typeArea, {
              // [s.hide]: !isLoggedIn(auth),
            })}
          >
            <ToolbarSelectField
              label={formatMessage(messages.courseTypeFilterLabel)}
              InputLabelProps={{
                classes: {
                  formControl: s.selectLabel,
                },
              }}
              value={type || 'all'}
              onChange={onContentTypeChange}
              classes={{
                textField: s.filterSelect,
                selectRoot: s.filterSelect,
                formRoot: s.formRootAllCourses,
                selectIcon: s.toolbarSelectIcon,
                select: s.allCoursesSelect,
              }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                },
              }}
              disabled={loadingCourses}
              role="button"
            >
              {contentTypeFilterOptions.map(option => (
                <MenuItem
                  className={s.font}
                  key={`content-type-filter-home-page-${option.value}`}
                  value={option.value}
                >
                  <Tooltip title={option.tooltipValue}>
                    <div className={s.courseTypeMenuItems}>{option.label}</div>
                  </Tooltip>
                </MenuItem>
              ))}
            </ToolbarSelectField>
          </div>

          {!search && (
            <div
              className={classNames(
                s.flex,
                s.courseTypeFilters,
                s.languageArea,
              )}
            >
              <ToolbarSelectField
                label={formatMessage(messages.languageFilterLabel)}
                InputLabelProps={{
                  classes: {
                    formControl: s.selectLabel,
                  },
                }}
                value={contentLangId || 'all'}
                onChange={onContentLanguageChange}
                classes={{
                  textField: s.filterSelect,
                  selectRoot: s.filterSelect,
                  formRoot: s.formRootAllCourses,
                  selectIcon: s.toolbarSelectIcon,
                  select: s.allCoursesSelect,
                }}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                }}
                disabled={loadingCourses}
                role="button"
              >
                {[
                  {
                    _id: 'all',
                    name: formatMessage(messages.allLabel),
                  },
                  ...contentLangs,
                ].map(option => (
                  <MenuItem
                    className={s.font}
                    key={`language-filter-home-page-${option._id}`}
                    value={option._id}
                  >
                    <Tooltip title={option.lang}>
                      <div className={s.courseTypeMenuItems}>{option.name}</div>
                    </Tooltip>
                  </MenuItem>
                ))}
              </ToolbarSelectField>
            </div>
          )}

          <div className={classNames(s.flex, s.orderFilter, s.orderArea)}>
            <div className={s.orderFilterInputs}>
              <span className={s.sortOrderLabel}>
                <FormattedMessage {...messages.orderTitle} />
              </span>
              <Select
                value={sortValue}
                onChange={onChangeSort}
                className={s.sortField}
                classes={{
                  select: s.sortFieldSelect,
                  disabled: s.disabledSelect,
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                disabled={
                  loadingCourses ||
                  loadingLessons ||
                  loadingContentCategories ||
                  loadingTags
                }
                IconComponent={IconComponent}
              >
                <MenuItem value="createDate">
                  <FormattedMessage {...messages.publishDateItemLabel} />
                </MenuItem>
                {isSearchEmpty && isLoggedIn(auth) && (
                  <MenuItem value="lastAccessDate">
                    <FormattedMessage
                      {...messages.recentlyAccessedMenuItemLabel}
                    />
                  </MenuItem>
                )}
                {isSearchEmpty && isLoggedIn(auth) && (
                  <MenuItem value="progress">
                    <FormattedMessage {...messages.progressMenuItemLabel} />
                  </MenuItem>
                )}
                <MenuItem value="name">
                  <FormattedMessage {...messages.nameMenuItemLabel} />
                </MenuItem>
                {isSearchEmpty && (
                  <MenuItem value="completed">
                    <FormattedMessage {...messages.popularityMenuItemLabel} />
                  </MenuItem>
                )}
              </Select>
              <Select
                value={sortOrderValue}
                onChange={onChangeSortOrder}
                className={s.sortOrderField}
                disabled={
                  loadingCourses ||
                  loadingLessons ||
                  loadingContentCategories ||
                  loadingTags
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                IconComponent={IconComponent}
              >
                <MenuItem value="asc">
                  <FormattedMessage {...messages.ascendingMenuItemLabel} />
                </MenuItem>
                <MenuItem value="desc">
                  <FormattedMessage {...messages.descendingMenuItemLabel} />
                </MenuItem>
              </Select>
            </div>
          </div>
          {/* <div className={s.clearAndModeContainer}> */}
          <div className={classNames(s.clearFilters, s.clearArea)}>
            <Tooltip title={formatMessage(messages.clearFilterLong)}>
              <Button
                color="primary"
                onClick={clearFilter}
                variant="outlined"
                classes={{ root: s.clearFiltersRoot }}
                disabled={
                  hasPristineState ||
                  loadingCourses ||
                  loadingLessons ||
                  loadingContentCategories ||
                  loadingTags
                }
              >
                <span className={s.clearFilterLongMsg}>
                  <FormattedMessage {...messages.clearFilterLong} />
                </span>
                <span className={s.clearFilterShotMsg}>
                  <FormattedMessage {...messages.clearFilterShot} />
                </span>
              </Button>
            </Tooltip>
          </div>
          <div className={classNames(s.flex, s.modeIconsContainer)}>
            <Tooltip title={formatMessage(messages.showList)}>
              <IconButton
                onClick={this.handleDisplayListClick}
                classes={{ root: s.displayModeBtn }}
                disabled={
                  displayItemsInColumn || loadingCourses || loadingLessons
                }
                aria-label={formatMessage(messages.showList)}
              >
                <ColumnIcon
                  className={classNames(s.displayModeIcon, {
                    [s.disabledIcon]:
                      displayItemsInColumn || loadingCourses || loadingLessons,
                  })}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={formatMessage(messages.showGrid)}>
              <IconButton
                onClick={this.handleDisplayPlateClick}
                classes={{ root: s.displayModeBtn }}
                disabled={
                  !displayItemsInColumn || loadingCourses || loadingLessons
                }
                aria-label={formatMessage(messages.showGrid)}
              >
                <GridIcon
                  className={classNames(s.displayModeIcon, {
                    [s.disabledIcon]:
                      !displayItemsInColumn || loadingCourses || loadingLessons,
                  })}
                />
              </IconButton>
            </Tooltip>
          </div>
          {/* </div> */}
        </section>
      </section>
    );
  }
}

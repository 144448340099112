import React from 'react';
import { Icon } from './index';

const SkholeIcon11 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M11.4,35.6c-0.5,0-1-0.2-1.4-0.5c-0.7-0.5-1.1-1.4-0.9-2.3l1.4-8.3l-6.1-5.9c-0.6-0.6-0.9-1.6-0.6-2.4
      c0.3-0.9,1-1.5,1.9-1.6l8.4-1.2l3.7-7.6c0.4-0.8,1.2-1.3,2.1-1.3s1.7,0.5,2.1,1.3c0,0,0,0,0,0l3.7,7.6l8.4,1.2
      c0.9,0.1,1.6,0.7,1.9,1.6c0.3,0.9,0.1,1.8-0.6,2.4l-6.1,5.9l1.4,8.3c0.2,0.9-0.2,1.8-0.9,2.3c-0.7,0.5-1.7,0.6-2.5,0.2L20,31.4
      l-7.5,3.9C12.2,35.5,11.8,35.6,11.4,35.6z M20,28.8c0.4,0,0.8,0.1,1.1,0.3l7.3,3.8L27,24.8c-0.1-0.8,0.1-1.5,0.7-2.1l5.9-5.8
      l-8.2-1.2c-0.8-0.1-1.4-0.6-1.8-1.3L20,7.1l-3.6,7.4c-0.3,0.7-1,1.2-1.8,1.3L6.4,17l5.9,5.8c0.6,0.5,0.8,1.3,0.7,2.1l-1.4,8.1
      l7.3-3.8C19.2,28.9,19.6,28.8,20,28.8z M25.9,13.4L25.9,13.4L25.9,13.4z M19.9,6.9L19.9,6.9L19.9,6.9z"
    />
  </svg>,
);

export default SkholeIcon11;

import { defineMessages } from 'react-intl';

export // eslint-disable-line import/prefer-default-export
const messages = defineMessages({
  search: {
    id: 'Tours.Courses.search',
    defaultMessage: 'Search courses by name and filters',
  },
  groupMenu: {
    id: 'Tours.Courses.groupMenu',
    defaultMessage:
      'Here you can do tasks such as exams and interact with your group',
  },
  help: {
    id: 'Tours.Courses.help',
    defaultMessage:
      "Here you'll find instructions on how to use the application",
  },
  notifications: {
    id: 'Tours.Courses.notifications',
    defaultMessage: "You'll get notifications of tasks assigned to you",
  },
  profile: {
    id: 'Tours.Courses.profile',
    defaultMessage:
      'From Profile-menu, you can view your certificates, statistics and change language of the user interface',
  },
  dashboard: {
    id: 'Tours.Courses.dashboard',
    defaultMessage:
      'From dashboard you can continue your last studied course or see a glance of current events',
  },
  coursesList: {
    id: 'Tours.Courses.coursesList',
    defaultMessage:
      'Here are your courses. You may add filters and sorting conditions or add them as favorites',
  },
});

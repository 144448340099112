import React from 'react';
import { Icon } from './index';

const SkholeIcon40 = Icon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 40 40"
    version="1.1"
    viewBox="0 0 40 40"
    xmlSpace="preserve"
  >
    <path
      d="M36.6 20c0 .3-.1.7-.4.9l-10.6 9.8c-.2.2-.5.3-.8.3-.3 0-.7-.1-.9-.4-.5-.5-.4-1.3.1-1.8l8.3-7.7H4.7c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h27.5L23.9 11c-.5-.5-.5-1.3-.1-1.8.5-.5 1.3-.5 1.8-.1l10.6 9.8c.1.1.1.2.2.3.1.3.2.6.2.8z"
      className="st1"
    />
  </svg>,
);

export default SkholeIcon40;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';

import Link from '../../../components/Link';
import messages from '../coursesMessges';
import s from './DashboardDiscussions.scss';

@injectIntl
@withStyles(s)
@connect(state => ({
  lang: state.intl.locale,
}))
export default class DashboardDiscussions extends React.Component {
  static propTypes = {
    followedDiscussions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    discussions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    intl: intlShape.isRequired,
  };

  render() {
    const { followedDiscussions, discussions } = this.props;

    if (_.isEmpty(followedDiscussions) && _.isEmpty(discussions)) {
      return null;
    }

    return (
      <div className={s.trendingTopicsAndNews}>
        <h2>
          <FormattedMessage {...messages.discussionsTitle} />
        </h2>

        {!_.isEmpty(discussions) && (
          <div className={s.forum}>
            <div className={s.forumLabel}>
              <FormattedMessage {...messages.classroomTitle} />
            </div>
            <div className={s.forumItems}>
              {_.map(discussions, discussion => (
                <div
                  className={s.discussionItem}
                  key={_.get(discussion, '_id')}
                >
                  <Link
                    className={s.discussionLink}
                    to={`/classroom/followed-discussions/${_.get(
                      discussion,
                      '_id',
                    )}`}
                  >
                    {discussion.name}
                  </Link>
                  <div className={classNames(s.flex, s.postDate)}>
                    <Typography className={s.discussionDate}>
                      {moment(
                        _.get(discussion, 'latestResponse.createDate'),
                      ).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                    &nbsp;
                    {_.get(discussion, 'latestResponse.createdBy._id') && (
                      <Typography
                        className={s.discussionAuthor}
                        component={Link}
                        to={`/profiles/${_.get(
                          discussion,
                          'latestResponse.createdBy._id',
                        )}`}
                      >
                        {_.get(
                          discussion,
                          'latestResponse.createdBy.lastName',
                          '',
                        )}
                        &nbsp;
                        {_.get(
                          discussion,
                          'latestResponse.createdBy.firstName',
                          '',
                        )}
                      </Typography>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {!_.isEmpty(followedDiscussions) && (
          <div className={s.news}>
            <div className={s.newsLabel}>
              <FormattedMessage {...messages.followedTitle} />
            </div>
            <div className={s.newsItems}>
              {_.map(followedDiscussions, discussion => (
                <div key={_.get(discussion, '_id')}>
                  <Link
                    className={s.discussionLink}
                    to={`/classroom/followed-discussions/${_.get(
                      discussion,
                      '_id',
                    )}`}
                  >
                    {discussion.name}
                  </Link>
                  <div className={classNames(s.flex, s.postDate)}>
                    <Typography className={s.discussionDate}>
                      {moment(
                        _.get(discussion, 'latestResponse.createDate'),
                      ).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                    &nbsp;
                    <Typography
                      className={s.discussionAuthor}
                      component={Link}
                      to={`/profiles/${_.get(
                        discussion,
                        'latestResponse.createdBy._id',
                      )}`}
                    >
                      {_.get(
                        discussion,
                        'latestResponse.createdBy.lastName',
                        '',
                      )}
                      &nbsp;
                      {_.get(
                        discussion,
                        'latestResponse.createdBy.firstName',
                        '',
                      )}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

/* eslint-disable import/prefer-default-export */

import { buildQuery } from '../helpers';

import {
  HOME_GET_LESSONS,
  HOME_GET_LESSONS_SUCCESS,
  HOME_GET_LESSONS_FAIL,
  HOME_RESET_LESSONS,
} from '../constants';

export function getLessons(params, doNotAppend = false) {
  const query = buildQuery(params);

  return {
    types: [HOME_GET_LESSONS, HOME_GET_LESSONS_SUCCESS, HOME_GET_LESSONS_FAIL],
    promise: fetch =>
      fetch(`/api/lessons?${query}`, {
        method: 'GET',
      }),
    doNotAppend,
  };
}

export function resetLessons() {
  return {
    type: HOME_RESET_LESSONS,
  };
}

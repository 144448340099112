import React from 'react';
import { Icon } from './index';

const SkholeIcon17BiggerSize = Icon(
  <svg
    x="0px"
    y="0px"
    // viewBox="0 0 28 28"
    // style={{ enableBackground: 'new 0 0 40 40' }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="null"
      id="svg_1"
      d="m26.082898,11.04523l-8.4422,0c-0.42211,0 -0.738692,-0.316582 -0.738692,-0.738692l0,-8.4422c0,-0.949747 -0.738692,-1.793967 -1.793967,-1.793967l-2.321605,0c-0.949747,0 -1.793967,0.738692 -1.793967,1.793967l0,8.4422c0,0.42211 -0.316582,0.738692 -0.738692,0.738692l-8.4422,0c-0.949747,0 -1.793967,0.738692 -1.793967,1.793967l0,2.321605c0,0.949747 0.738692,1.793967 1.793967,1.793967l8.4422,0c0.42211,0 0.738692,0.316582 0.738692,0.738692l0,8.4422c0,0.949747 0.738692,1.793967 1.793967,1.793967l2.427132,0c0.949747,0 1.793967,-0.738692 1.793967,-1.793967l0,-8.4422c0,-0.42211 0.316582,-0.738692 0.738692,-0.738692l8.4422,0c0.949747,0 1.793967,-0.738692 1.793967,-1.793967l0,-2.321605c-0.105527,-0.949747 -0.84422,-1.793967 -1.899495,-1.793967z"
    />
  </svg>,
);

export default SkholeIcon17BiggerSize;

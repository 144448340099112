/* eslint-disable import/prefer-default-export */

import {
  GET_DISCUSSIONS_REQUEST,
  GET_DISCUSSIONS_SUCCESS,
  GET_DISCUSSIONS_FAIL,
  RESET_DISCUSSIONS,
} from '../constants';
import { buildQuery } from '../helpers';

export function getDiscussions(
  params = { page: 1, perPage: 50, sort: '-createDate', search: '' },
) {
  const query = buildQuery(params);

  return {
    types: [
      GET_DISCUSSIONS_REQUEST,
      GET_DISCUSSIONS_SUCCESS,
      GET_DISCUSSIONS_FAIL,
    ],
    promise: fetch =>
      fetch(`/api/discussions?${query}`, {
        method: 'GET',
      }),
  };
}

export function reset() {
  return {
    type: RESET_DISCUSSIONS,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import _ from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import withStyles from 'isomorphic-style-loader/withStyles';

import messages from '../coursesMessges';
import s from './CategoryCollapse.scss';
import { ModalCloseBtn } from '../../../components/statelessComponents/Buttons/ButtonsInModals';
import CircularProgress from '../../../components/CircularProgress';
import modalStyles from '../../../components/styles/Modal.scss'; // eslint-disable-line

const CategoryCollapse = ({
  loadingCourses,
  pendingCategories,
  categories,
  toggleSelectAllContentCategories,
  handleToggleContentCategory,
  handleCollapseIndex,
  isIndexExpanded,
  isLoadedContentCategories,
  width,
  anchorEl,
  intl: { formatMessage },
  nameMapper,
}) => {
  const categoryContent = (
    <div className={s.popoverContent}>
      {loadingCourses && (
        <div className={s.loadingWrapper}>
          <CircularProgress size={100} />
        </div>
      )}
      <Grid className={s.tagsGrid} spacing={0} container>
        <Grid sm={12} item>
          <FormControlLabel
            classes={{ root: s.checkboxSelectAll }}
            control={
              <Checkbox
                checked={
                  pendingCategories.length === categories.length &&
                  categories.length > 1
                }
                indeterminate={
                  !!pendingCategories.length &&
                  pendingCategories.length !== categories.length
                }
                disabled={loadingCourses || categories.length <= 1}
                onClick={toggleSelectAllContentCategories}
                color="primary"
                classes={{ root: s.categoryCheckbox }}
                icon={<CheckBoxOutlineBlankIcon className={s.checkboxIcon} />}
                indeterminateIcon={
                  <IndeterminateCheckBoxOutlinedIcon
                    className={s.checkboxIcon}
                  />
                }
                checkedIcon={
                  <CheckBoxOutlinedIcon className={s.checkboxIcon} />
                }
              />
            }
            label={formatMessage(messages.selectAllLabel)}
          />
        </Grid>
        <div className={s.categories}>
          <Grid
            container
            direction={isWidthDown('xs', width) ? 'column' : 'row'}
          >
            {categories
              .slice(0, Math.round(categories.length / 2))
              .map(category => (
                <Grid key={category._id} sm={12} item>
                  <FormControlLabel
                    classes={{
                      root: s.checkboxFormControl,
                      label: s.checkboxFormControlLabel,
                    }}
                    control={
                      <Checkbox
                        checked={pendingCategories.indexOf(category._id) !== -1}
                        onClick={handleToggleContentCategory(category._id)}
                        disabled={loadingCourses}
                        color="primary"
                        classes={{ root: s.categoryCheckbox }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            className={s.checkboxIcon}
                          />
                        }
                        checkedIcon={
                          <CheckBoxOutlinedIcon className={s.checkboxIcon} />
                        }
                      />
                    }
                    label={nameMapper(category)}
                  />
                </Grid>
              ))}
          </Grid>
          <Grid
            container
            direction={isWidthDown('xs', width) ? 'column' : 'row'}
          >
            {categories
              .slice(Math.round(categories.length / 2))
              .map(category => (
                <Grid key={category._id} sm={12} item>
                  <FormControlLabel
                    classes={{
                      root: s.checkboxFormControl,
                      label: s.checkboxFormControlLabel,
                    }}
                    control={
                      <Checkbox
                        checked={pendingCategories.indexOf(category._id) !== -1}
                        onClick={handleToggleContentCategory(category._id)}
                        disabled={loadingCourses}
                        color="primary"
                        classes={{ root: s.categoryCheckbox }}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            className={s.checkboxIcon}
                          />
                        }
                        checkedIcon={
                          <CheckBoxOutlinedIcon className={s.checkboxIcon} />
                        }
                      />
                    }
                    label={nameMapper(category)}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      </Grid>
    </div>
  );
  if (isWidthDown('xs', width)) {
    return (
      <Collapse
        classes={{ container: s.collapseContainer }}
        in={isIndexExpanded && isLoadedContentCategories}
      >
        {categoryContent}
        {!_.isEmpty(pendingCategories) && (
          <Button
            color="primary"
            onClick={handleCollapseIndex}
            variant="outlined"
            className={s.applyCategoryFilterBtn}
          >
            <FormattedMessage {...messages.applyCategoryFilter} />
          </Button>
        )}
      </Collapse>
    );
  }
  return (
    <Popover
      open={isIndexExpanded && isLoadedContentCategories}
      anchorEl={anchorEl}
      anchorReference="anchorEl"
      anchorPosition={{ top: 200, left: 400 }}
      onClose={handleCollapseIndex}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{ paper: s.tagsIndexPopover }}
    >
      {categoryContent}
      <ModalCloseBtn
        onClick={handleCollapseIndex}
        className={classNames(
          modalStyles.closeModalBtn,
          s.categoryModalCloseBtn,
        )}
        data-test="category-modal-close-button"
      />
    </Popover>
  );
};

CategoryCollapse.propTypes = {
  categories: PropTypes.array.isRequired, // eslint-disable-line
  pendingCategories: PropTypes.array.isRequired, // eslint-disable-line
  toggleSelectAllContentCategories: PropTypes.func.isRequired,
  loadingCourses: PropTypes.bool.isRequired,
  handleToggleContentCategory: PropTypes.func.isRequired,
  handleCollapseIndex: PropTypes.func.isRequired,
  isIndexExpanded: PropTypes.bool.isRequired,
  isLoadedContentCategories: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  width: PropTypes.string.isRequired,
  anchorEl: PropTypes.func.isRequired,
  nameMapper: PropTypes.func.isRequired,
};

CategoryCollapse.defaultProps = {};

export default compose(
  withWidth({ withTheme: true }),
  injectIntl,
  withStyles(modalStyles),
  withStyles(s),
)(CategoryCollapse);

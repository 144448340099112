import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './CircularProgressSmall.scss';

const CircularProgressSmall = ({ size, styles, wrapperClassName }) => (
  <div className={wrapperClassName}>
    <progress
      className={s.circularProgress}
      style={{
        width: size,
        height: size,
        ...styles,
      }}
    />
  </div>
);

CircularProgressSmall.propTypes = {
  size: PropTypes.number,
  styles: PropTypes.object, // eslint-disable-line
  wrapperClassName: PropTypes.string,
};

CircularProgressSmall.defaultProps = {
  size: 25,
  wrapperClassName: '',
  styles: {},
};

export default withStyles(s)(CircularProgressSmall);

import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import muiWithStyles from '@material-ui/core/styles/withStyles';
import { Interweave } from 'interweave';
import { buildQuery, handleErroredImage } from '../../helpers';
import isLoggedIn from '../../helpers/checkAuth';
import Link from '../Link';
import RestrictedAccessModal from '../RestrictedAccessModal/RestrictedAccessModal';

import s from './LessonItem.scss';

const messages = defineMessages({
  timeText: {
    id: 'LessonItem.timeText',
    defaultMessage: '{minutes} min',
  },
  noAccess: {
    id: 'LessonItem.noAccess',
    defaultMessage: 'You don’t have access to this content yet',
  },
  typeLesson: {
    id: 'Courses.typeLesson',
    defaultMessage: 'Lesson',
  },
});

const styles = theme => ({
  courseProgressRoot: {
    backgroundColor: '#F6F6F7',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  courseProgressBar: {
    backgroundColor: _.get(theme.palette.primary.light),
  },
});

@injectIntl
@withStyles(s)
@muiWithStyles(styles)
@connect(state => ({
  auth: state.auth,
  lang: state.intl.locale,
}))
export default class LessonItem extends Component {
  static propTypes = {
    auth: PropTypes.shape({}).isRequired,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.shape({
        path: PropTypes.string.isRequired,
      }),
      time: PropTypes.number,
      isAvailable: PropTypes.bool.isRequired,
      shortDescription: PropTypes.string,
      isGloballyFree: PropTypes.bool,
      isCompleted: PropTypes.bool,
      courseProgressRoot: PropTypes.object, // eslint-disable-line
      courseProgressBar: PropTypes.object, // eslint-disable-line
    }).isRequired,
    disableHover: PropTypes.bool,
    className: PropTypes.string,
    lang: PropTypes.string.isRequired,
    inOneColumn: PropTypes.bool.isRequired,
    classes: PropTypes.shape({
      courseProgressRoot: PropTypes.shape({}),
      courseProgressBar: PropTypes.shape({}),
    }).isRequired,
    intl: intlShape.isRequired,
    searchSessionId: PropTypes.string,
  };

  static defaultProps = {
    disableHover: false,
    className: '',
    searchSessionId: '',
  };

  static contextTypes = {
    cloudfrontUrl: PropTypes.string,
  };

  state = {
    isRestrictedAccessModalOpened: false,
  };

  checkAuth = event => {
    const { auth, item } = this.props;

    if (item.isGloballyFree || (isLoggedIn(auth) && item.isAvailable)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isRestrictedAccessModalOpened: true,
    });
  };

  closeRestrictedAccessModal = () => {
    this.setState({
      isRestrictedAccessModalOpened: false,
    });
  };

  render() {
    const {
      item,
      disableHover,
      className,
      classes,
      intl: { formatMessage },
      lang,
      inOneColumn,
      searchSessionId,
      ...rest
    } = this.props;
    const { isRestrictedAccessModalOpened } = this.state;

    const timeSeconds = (item.time || 0) % 60;
    const timeMinutes = ((item.time || 0) - timeSeconds) / 60;

    return (
      <Tooltip
        placement="top"
        title={!item.isAvailable ? formatMessage(messages.noAccess) : item.name}
      >
        <Paper
          className={classNames(
            s.lessonItem,
            disableHover && s.disableHover,
            !item.isAvailable && s.addonLesson,
            className,
          )}
          {...rest}
          data-test="lesson-item"
          data-test-value={item.name}
        >
          <ButtonBase
            component={Link}
            to={`/lessons/${item.slug}?${buildQuery({ searchSessionId })}`}
            onClick={this.checkAuth}
            className={classNames(s.lessonItemLink, {
              [s.inOneColumn]: inOneColumn,
            })}
            focusRipple
          >
            <img
              src={`${this.context.cloudfrontUrl}/default-lesson-preview.jpeg`}
              alt={item.name}
              title={item.name}
              className={classNames(s.lessonItemImage, {
                [s.inOneColumn]: inOneColumn,
              })}
              onError={handleErroredImage}
            />
            <div
              className={classNames(s.lessonItemTextDataWrapper, {
                [s.inOneColumn]: inOneColumn,
              })}
            >
              <Typography className={s.itemType}>
                {_.toUpper(formatMessage(messages.typeLesson))}
              </Typography>
              <Typography
                variant="subtitle1"
                className={classNames(s.lessonItemTitle, {
                  [s.inOneColumn]: inOneColumn,
                })}
                data-test="lesson-name"
              >
                <Interweave
                  content={item.name}
                  tagName="div"
                  className={classNames(s.lessonItemTitleHTMLContent, {
                    [s.inOneLine]: inOneColumn,
                    [s.inTwoLine]: !inOneColumn,
                  })}
                />
              </Typography>
              {inOneColumn && (
                <div className={s.description}>
                  <Interweave content={item.shortDescription} noWrap />
                </div>
              )}
              <div
                className={classNames(s.lessonItemDetails, {
                  [s.inOneColumn]: inOneColumn,
                })}
              >
                <Typography
                  variant="subtitle1"
                  className={classNames(s.lessonItemDetailsStats, {
                    [s.inOneColumn]: inOneColumn,
                  })}
                >
                  {!!timeMinutes && (
                    <FormattedMessage
                      {...messages.timeText}
                      values={{ minutes: timeMinutes }}
                    />
                  )}
                </Typography>
              </div>
            </div>
          </ButtonBase>
          {!!item.isCompleted && (
            <LinearProgress
              variant="determinate"
              value={item.isCompleted ? 100 : 0}
              color="primary"
              classes={{
                root: classes.courseProgressRoot,
                barColorSecondary: classes.courseProgressBar,
              }}
            />
          )}
          <Modal
            open={isRestrictedAccessModalOpened}
            onClose={this.closeRestrictedAccessModal}
          >
            <RestrictedAccessModal onClose={this.closeRestrictedAccessModal} />
          </Modal>
        </Paper>
      </Tooltip>
    );
  }
}

/* eslint-disable import/prefer-default-export */

import {
  GET_CONTENT_CATEGORIES,
  GET_CONTENT_CATEGORIES_SUCCESS,
  GET_CONTENT_CATEGORIES_FAIL,
  RESET_CONTENT_CATEGORIES,
} from '../constants';
import { buildQuery } from '../helpers';

export function getContentCategories(
  params = {
    page: 1,
    perPage: 50,
    sort: 'name.fi-FI',
    search: '',
    archived: false,
    isGeneral: false,
  },
) {
  const query = buildQuery(params);

  return {
    types: [
      GET_CONTENT_CATEGORIES,
      GET_CONTENT_CATEGORIES_SUCCESS,
      GET_CONTENT_CATEGORIES_FAIL,
    ],
    promise: fetch =>
      fetch(`/api/content-categories?${query}`, {
        method: 'GET',
      }),
  };
}

export function reset() {
  return {
    type: RESET_CONTENT_CATEGORIES,
  };
}

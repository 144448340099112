/* eslint-disable import/prefer-default-export */

import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  RESET_TAGS,
} from '../constants';
import { buildQuery } from '../helpers';

export function getTags(
  params = {
    page: 1,
    perPage: 50,
    sort: '-name',
    search: '',
    archived: false,
  },
) {
  const query = buildQuery(params);

  return {
    types: [GET_TAGS, GET_TAGS_SUCCESS, GET_TAGS_FAIL],
    promise: fetch =>
      fetch(`/api/tags?${query}`, {
        method: 'GET',
      }),
  };
}

export function reset() {
  return {
    type: RESET_TAGS,
  };
}
